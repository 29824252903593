<script>
import appConfig from "@/app.config";
import JsonExcel from "vue-json-excel";
import moment from "moment";
import {digimrBestCallStatus} from "../../../../path";

export default {
  props : ['filters'],
  components: { 
    JsonExcel 
  },
  watch : {
    filters : {
      deep : true,
      handler() {
        this.getHistories();
      }
    },
    // perPage(){
    //   this.getHistories();
    // },
    currentPage() {
      this.getHistories();
    }
  },
  data() {
    return {
      telecallers : [],
      d_telecallers : [],
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      last_page : 1,
      pageOptions: [15, 30, 45, 60, 75],
      filter: null,
      filterOn: [],
      sortBy: "last_called_at",
      sortDesc: true,
      key : 0,
      Notes : null,

      // jsonExcel : {
      //   "Name" : {
      //     field : 'name'
      //   },
      //  'speciality' : "Speciality",
      //  'city' : 'city'
      // },
      fields: [
        {key: "name", sortable: true, label : 'Doctor'},
        {key : 'speciality', label: "Speciality", sortable: true },
        {key : 'city', sortable: true },
        {key : 'state', sortable: true },
        // {key : 'mobile_number', label: "Mobile Number", sortable: true },
        {key : 'whatsapp_number_hidden', label: "Whatsapp Number", sortable: true},
        // {key : 'email',label : 'Email', sortable: true },
        {key : 'status', label: "Call Status", sortable: true, color : 'grey', requiresAdmin: true},
        {key : 'sub_status', label: "Call Status", sortable: true, color : 'grey', permission: 'show_call_sub_status'},
        {key : 'total_calls_received', label: "Total Calls", sortable: true },
        {key : 'last_called_at',label : 'Last Called At', sortable: true },
        {key : 'address',label : 'Address', sortable: true, permission: 'show_call_sub_status'},
        {key : 'opted_in',label : 'Call Opt-in', sortable: true, permission: 'show_call_sub_status'},
        {key : 'whatsapp_optin',label : 'Whatsapp Opt-in', sortable: true, permission: 'show_call_sub_status'}, 
      ],
    };
  },
  computed: {
    computedFields() {
      // If the user isn't an admin, filter out fields that require auth.
      if(this.$store.state.authfack.user.role != 'AnalyticsSuperAdmin')
        return this.fields.filter(field => !field.requiresAdmin);
      // If the user IS an admin, return all fields.
      return this.fields.filter(f => f.permission == null || this.$store.state.authfack.user.permissions.includes(f.permission));
    },
    rows() {
      return this.totalRows;
    },
  },
  mounted() {
    // Set the initial number of items
    this.getHistories();
  },
  methods: {
    async getHistories(){
      try{
        this.telecallers = []; 
        let params = Object.keys(this.filters).length > 0 ? this.filters : {};
        params.page = this.currentPage;
        params.limit = this.perPage;
        const response = await this.$http.get(appConfig.api_base_url + digimrBestCallStatus,{
          params : params
        });
        this.totalRows = response.data.best_call_status.total;
        this.perPage = response.data.best_call_status.per_page;
        this.last_page = response.data.best_call_status.last_page;

        response.data.best_call_status.data.forEach(res1 => {
          let arr = ['first_name','last_name','mobile_number','whatsapp_number_hidden','email','total_calls_received','address','speciality'
            ,'state','city','opted_in','whatsapp_optin','last_called_at','status' ];
          let data = {};
          // this.telecallers[index].idle_time = moment(res1.last_call).fromNow();
          arr.forEach(a => {
            if(a === 'call_time_in_sec'){
              data[a] = res1[a] + ' sec';
            }else if (a === 'file'){
              if(res1[a] == '' || res1[a] == null || res1[a] == 'null'){
                data[a] = null;
              }else{
                data[a] = appConfig.recording_cdn + moment(res1.last_call).format('DD_MMM_YYYY') + '/' + res1[a];
              }
              // data[a] = appConfig.recording_cdn + moment(res1.last_call).format('DD_MMM_YYYY') + '/' + res1[a];

            }else{
              data[a] = res1[a];
            }
          });
          this.telecallers.push(data);
        });
        this.d_telecallers = [];
        this.key += 1;
      }catch(err) {
        console.log(err);
      }
    },
    async getDownloadHistories() {
      try {
        this.d_telecallers = [];
        delete this.filters.page;
        delete this.filters.limit;
        const response = await this.$http.get(appConfig.api_base_url + digimrBestCallStatus,{
          params: this.filters,
        });
        this.d_telecallers = response.data.best_call_status.map(f => {
          f.whatsapp_number = f.whatsapp_number_hidden;
          f.status = f.status.sub_status_display_key;
          delete f.whatsapp_number_hidden;
          return f;
        });
        this.totalRows = this.d_tellcallers.length;
      }catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <!-- <h4 class="card-title">Latest Call Activity</h4> -->
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show
                 <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <div class="d-flex justify-content-end mb-2">
            <template v-if="this.d_telecallers.length > 1">
              <JsonExcel
                class="btn btn-primary"
                :data="d_telecallers"
                name="doctor_best_call_status.csv"
                type="csv">
                <i class="mdi mdi-download"></i>Download
              </JsonExcel>
            </template>
            <template v-else>
              <b-button :pressed="false"  variant="primary" @click="getDownloadHistories()">Prepare Download</b-button>
            </template>
          </div>
          <div class="table-responsive mb-0">
            <b-table
                :key="key"
                :items="telecallers"
                :fields="computedFields"
                responsive="sm"
                :per-page="0"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
            >
              <template v-slot:cell(name)="row">
                {{row.item.first_name}} {{row.item.last_name}}
              </template>
              <template v-slot:cell(status)="row">
                {{row.item.status.display_key}}
              </template>
              <template v-slot:cell(sub_status)="row">
                {{row.item.status.sub_status_display_key}}
              </template>
              <template v-slot:cell(description)="row">
                <b><i>{{row.value}}</i></b>
              </template>
              <template v-slot:cell(opted_in)="row">
                    <i class="fas fa-check-circle" style="color: #a4a4a4" v-if="row.item.opted_in != 1"></i>
                    <i class="fas fa-check-circle" style="color: #1cbb8c" v-else></i>
                </template>
                <template v-slot:cell(whatsapp_optin)="row">
                    <i class="fas fa-check-circle" style="color: #a4a4a4" v-if="row.item.whatsapp_optin != true"></i>
                    <i class="fas fa-check-circle" style="color: #1cbb8c" v-else></i>
                </template>
              
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>